
import Common from '~/mixins/common'
import Masters from '~/mixins/masters'
import _ from 'lodash';
import { httpClient } from '~/utils/axios'
import { cl_payment, taxRateOptions } from '~/utils/models'
import dayjs from 'dayjs';
import ClPaymentkaikei from '~/components/common/cl_payment/Kaikei'

export default {
  mixins: [Common,Masters],
  components: {
    ClPaymentkaikei,
  },
  props: {
    clientSelectable: {type: Boolean, default: true},
  },
  data() {
    return {
      // modal
      showPaymentModal: false,
      showItemsModal: false,
      showComfirmModal: false,

      edit_client: null,
      edit_cl_payment: null,
      payment_method_id: null,
      with_no_client: false,

      // 支払い設定
      paymentTypeOptions: [
        { text: '商品', value: "item" },
        { text: '回数券', value: "ticket" },
        { text: '手入力', value: "handle" },
      ],
      
      // 商品
      cartItems: [],
      currentCategories: [],
      taxRateOptions: taxRateOptions,

      // 支払いデータ
      payment_type: "item",
      payment_with_online: true,
      // 3、5、6、10、12、15、18、20、24
      cardPaymentNumber: 1,
      cardPaymentNumberOptions: [
        { text: '1回', value: 1 },
        { text: '3回', value: 3 },
        { text: '5回', value: 5 },
        { text: '6回', value: 6 },
        { text: '10回', value: 10 },
        { text: '12回', value: 12 },
        { text: '15回', value: 15 },
        { text: '18回', value: 18 },
        { text: '20回', value: 20 },
        { text: '24回', value: 24 },
      ],

      // 人を選ぶケース
      edit_client_id: null,
      edit_course_uid: null,

      // 月回数制の付与月
      add_month: 0,
      months: [
        {text: "今月", value: 0},
        {text: "来月", value: 1},
        {text: "再来月", value: 2},
      ]
    }
  },
  async mounted() {
    let self = this
  },
  computed: {
    // item
    itemsModalTitle(){
      let size = 0
      let price = 0
      this.currentCategories.forEach(cat => {
        cat.items.forEach(it => {
          size += it.count
          price += it.count * it.price
        })
      })
      return `${size}個 / ${price}円`
    },

    // validation
    paymentInvalid(){
      let invalid = false
      // 無人支払い対応
      if(this.with_no_client === false && !this.edit_client) invalid = this
      if(this.payment_type === 'handle'){
        // invalid = false
      }else if(this.payment_type === 'ticket'){
        if(!this.edit_client) invalid = true
      }else if(this.payment_type === 'item'){
        if(this.cartItems.length <= 0) invalid = true
      }
      return invalid
    },
    onlinePaymentInvalid(){
      if(this.paymentInvalid) return true
      let invalid = false
      if(!this.edit_client || !this.edit_client.has_card) invalid = true
      return invalid
    },

    currentFilterdCourses(){
      let courses = _.filter(this.courses, c => {
        if(c.use_new_version){
          return c.reserve_type === this.edit_client.reserve_type
        }else{
          return true
        }
      })
      return courses
    },
  },
  methods: {

    //// 手動・チケット　//////////////////////
    // 手動・チケットに関して小計とポイント数が決まったらその後の計算
    changePrice(){
      this.edit_cl_payment.disacount_price_sum = this.edit_cl_payment.spend_point
      this.edit_cl_payment.price_sum = this.edit_cl_payment.price_subtotal - this.edit_cl_payment.disacount_price_sum
      this.edit_cl_payment.normal_tax_sum = parseInt(this.edit_cl_payment.price_sum * (0.1/1.1))
      this.edit_cl_payment.keigen_tax_sum = 0
      this.edit_cl_payment.tax_sum = this.edit_cl_payment.normal_tax_sum + this.edit_cl_payment.keigen_tax_sum
    },
    changeCourse(){
      console.log("changeCourse")
      let course = _.find(this.courses, ["uid", this.edit_course_uid])
      this.edit_cl_payment.name = course.name
      this.edit_cl_payment.description = course.description
      this.edit_cl_payment.price_subtotal = course.price
      this.edit_cl_payment.ticket_number = course.ticket_number
      this.edit_cl_payment.expire_days = course.expire_days
      this.edit_cl_payment.able_menu_ids = course.able_menu_ids
      this.changeTicketLimit()
      this.changePrice()
    },
    changeTicketLimit(){
      console.log("changeTicketLimit")
      this.edit_cl_payment.use_to = dayjs(this.edit_cl_payment.use_from).subtract(1,"d").add(this.edit_cl_payment.expire_days, "d").format("YYYY-MM-DD")
    },

    //// 商品　//////////////////////
    openItems(){
      this.setUpCategories()
      this.add_month = 0
      this.showItemsModal = true
    },
    addItem(item){
      console.log("addItem", item)
      item.count += 1
    },
    addItemsToCart(){
      let selectedItems = []
      this.currentCategories.forEach(cat => {
        cat.items.forEach(it => {
          if(it.count > 0){
            it.sub_total = it.price * it.count
            selectedItems.push(it)
          }
        })
      })
      this.cartItems = _.concat(this.cartItems, selectedItems)
      this.cartUpdate()
      this.showItemsModal = false
    },
    deleteItem(index){
      this.cartItems.splice(index, 1);
      this.cartUpdate()
    },
    setUpCategories(){
      let self = this
      // setup items
      let base_items = _.cloneDeep(self.items)
      base_items.forEach(it => {
        it.count = 0
        it.is_custom = false
      })
      let currentCategories = _.cloneDeep(self.item_categories)
      currentCategories.forEach(cat => {
        cat.items = _.filter(base_items, it => {
          return it.item_category_id === cat.id
        })
      })
      currentCategories.push({
        name: "無分類",
        items: [{
          id: null,
          name: "カスタム",
          name_inner: "カスタム",
          price: 0,
          tax_rate: 10,
          manage_stock: false,
          is_custom: true,
          count: 0,
          item_category_id: null,
        }],
      })
      console.log("currentCategories",currentCategories)
      this.currentCategories = currentCategories
    },
    //　FIXME: ポイント引いた場合は通常税率のものからポイント分を引く
    cartUpdate(){
      let price_subtotal = 0
      let normal_item_sumtotal = 0
      let keigen_item_sumtotal = 0
      this.cartItems.forEach(it => {
        it.sub_total = it.price * it.count
        price_subtotal += it.sub_total
        if(it.tax_rate === 10) normal_item_sumtotal += it.sub_total
        if(it.tax_rate === 8) keigen_item_sumtotal += it.sub_total
      })
      this.edit_cl_payment.price_subtotal = price_subtotal
      this.edit_cl_payment.normal_tax_sum = parseInt(normal_item_sumtotal * (10/110))
      this.edit_cl_payment.keigen_tax_sum = parseInt(keigen_item_sumtotal * (8/108))
      this.edit_cl_payment.tax_sum = this.edit_cl_payment.normal_tax_sum + this.edit_cl_payment.keigen_tax_sum
      this.edit_cl_payment.disacount_price_sum = 0
      this.edit_cl_payment.price_sum = this.edit_cl_payment.price_subtotal - this.edit_cl_payment.disacount_price_sum
    },

    //// 支払い　//////////////////////
    paymentNew(client, payment_type = null){
      console.log("paymentNew", client)
      if(!this.company.use_payment) return
      // 初期データ
      if(client){
        this.edit_client = _.cloneDeep(client)
        this.edit_client_id = this.edit_client.id
      }else{
        this.edit_client = null
        this.edit_client_id = null
        this.getClients()
      }
      this.$store.dispatch("company/getCourses")
      this.$store.dispatch("company/getItems")
      this.$store.dispatch("company/getItemCategories")
      this.$store.dispatch("company/getPaymentMethods")

      // 初期化
      this.payment_type = payment_type ? payment_type : "item"
      this.payment_method_id = null
      this.with_no_client = false
      this.changePaymentType()
      if(!this.company.use_items){
        this.paymentTypeOptions[0].disabled = true
        this.payment_type = "ticket"
      }
      this.showComfirmModal = false
      this.showPaymentModal = true
    },
    changeClient(){
      this.edit_client = _.find(this.clients, ["id", this.edit_client_id])
    },
    changeWithNoClient(){
      this.edit_client_id = null
      this.edit_client = null
    },
    changePaymentType(){
      this.edit_cl_payment = _.cloneDeep(cl_payment)
      this.edit_course_uid = null
      this.cartItems = []
      this.currentCategories = []
      this.cartUpdate()
      if(this.payment_type === "item") this.edit_cl_payment.name = "商品購入"
    },
    async submitPay(event){
      console.log("submitPay",event)
      this.payment_method_id = event.payment_method_id
      this.edit_cl_payment.azukari_price = event.azukari_price
      this.edit_cl_payment.otsuri_price = event.otsuri_price
      this.payment_with_online = event.payment_with_online

      // 確認モーダル立ち上げる
      this.cardPaymentNumber = 1
      this.showComfirmModal = true
    },
    startPay(){
      let canOnline = (this.edit_client && this.edit_client.has_card) ? true : false
      this.$refs.kaikei.openModal(this.edit_cl_payment.price_sum, canOnline)
    },
    async paymentCreate(){
      let self = this
      self.showComfirmModal = false
      self.$store.commit("loading/start", true)
      await httpClient
        .post(`/cmp/floor/cl_payments.json`, {
          payment_type: self.payment_type,
          payment_with_online: self.payment_with_online,
          card_payment_number: self.cardPaymentNumber,
          payment_method_id: self.payment_method_id,
          client_uid: self.edit_client ? self.edit_client.uid : null,
          course_uid: self.edit_course_uid,
          cl_payment: self.edit_cl_payment,
          cartItems: self.cartItems,
          add_month: self.add_month,
        })
        .then(async (res) => {
          if (res.data.status === 'success') {
            window.storeCtl.commit("event/updateClPayment")
            window.storeCtl.commit("loading/stop")
            window.storeCtl.commit("alert/setSuccess", "支払いをしました")
            // モーダルを閉じる
            this.$refs.kaikei.closeModal()
            self.showPaymentModal = false
            this.$emit("paied", self.edit_client)
            // 支払いページに飛ぶ
            if(self.clientSelectable){
              self.$router.push("/cl_payments/")
            }      
          } else {
            window.storeCtl.commit("loading/stop")
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          window.storeCtl.commit("loading/stop")
        })
    },
  },
}
