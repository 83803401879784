import { httpClient } from '~/utils/axios'
import _ from 'lodash'
import dayjs from 'dayjs'

export const state = () => ({
  code: null,
  syncAt: null
})
export const mutations = {
  setCode(state, payload) {
    if (!state.code) state.code = payload
  },
  doneSyncAt(state) {
    state.syncAt = dayjs().format('YYYY-MM-DD HH:mm:SS')
  }
}
export const actions = {
  //// auth
  async sync(context) {
    if (!context.state.code) return
    if (context.state.syncAt) return
    // await httpClient
    // .post('/front/account/sync_tracking_code.json', {tracking_code: context.state.code})
    // .then((res) => {
    //   if (res.data.status === 'success') {
    //     context.commit('doneSyncAt')
    //   } else {
    //     window.storeCtl.commit("alert/setError", res.data.message)
    //   }
    // })
  }
}
