
import _ from 'lodash';
import { httpClient } from '~/utils/axios'
import Common from '~/mixins/common'

export default {
  middleware: ['authenticated'],
  mixins: [Common],
  components: {
  },
  props: {
  },
  data() {
    return {
      show: false,
      edit_client: null,
      card_number: null,
      card_expire_mm: null,
      card_expire_yy: null,
      security_code: null,
      cardholder_name: null,
      token: null,
      error: null,
    }
  },
  async mounted() {
    let self = this
  },
  computed: {
  },
  methods: {
    open(client){
      this.edit_client = _.cloneDeep(client)
      this.show = true
      console.log("submitCard",this.company.pay_api_key)
    },
    async submitCard(){
      let self = this
      var data = {};
      data.token_api_key = self.company.pay_api_key
      data.card_number = self.card_number
      data.card_expire = `${self.card_expire_mm}/${self.card_expire_yy}` // "12/25"
      data.security_code = self.security_code // "111"
      data.cardholder_name = self.cardholder_name // "test taro"
      data.lang = "ja";  
      var url = "https://api.veritrans.co.jp/4gtoken" // document.getElementById('token_api_url').innerText;
      var xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      xhr.setRequestHeader('Accept', 'application/json');
      xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
      xhr.addEventListener('loadend', function () {
        if (xhr.status === 0) {
          window.storeCtl.commit("alert/setError", "トークンサーバーとの接続に失敗しました")
          return;
        }
        var response = JSON.parse(xhr.response);
        if (xhr.status == 200) {
          console.log("token", response.token)
          self.token = response.token
          self.updateCard()
        }else{
          window.storeCtl.commit("alert/setError", response.message)
        }
      });
      xhr.send(JSON.stringify(data));
    },
    async updateCard(){
      let self = this
      self.$store.commit("loading/start")
      await httpClient
        .put(`/cmp/floor/clients/${self.edit_client.uid}/update_card.json`, {card_token: self.token, isDG: true})
        .then(async (res) => {
          self.$store.commit("loading/stop")
          if (res.data.status === 'success') {
            self.$store.commit("alert/setSuccess", "クレジットカードを更新しました")
            self.$emit("updated", res.data.data.client)
            self.card_number = null
            self.card_expire_mm = null
            self.card_expire_yy = null
            self.security_code = null
            self.cardholder_name = null
            self.error = null
            self.$refs.observer.reset();
            self.show = false
          } else {
            self.$store.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          self.$store.commit("loading/stop")
        })
    },
  }
}
