import { mapState } from 'vuex'
import { httpClient } from '~/utils/axios'
import _ from 'lodash'
import dayjs from 'dayjs'
import Compressor from 'compressorjs'

export default {
  data() {
    return {
      relatedLessons: []
    }
  },
  async mounted() {
    let self = this

    // ページトップへ
    document.querySelector('body').scrollTop = 0

    // detect deviseit
    if (self.$mq === 'pc') {
      this.$store.commit('devise/setDrawer', true)
      this.$store.commit('devise/setIsPC', true)
    } else {
      this.$store.commit('devise/setIsPC', false)
    }
  },
  computed: {
    ...mapState({
      userAgent: state => state.userAgent,
      isPC: state => state.devise.isPC,
      isIPad: state => state.devise.isIPad,
      isTouchDevice: state => state.devise.isTouchDevice,
      isLogin: state => !!state.auth.authToken,
      new_alert_size: state => state.auth.new_alert_size,
      company: state => state.auth.company,
      staff: state => state.auth.staff,
      settings: state => state.auth.settings,
      submited_tasks: state => state.auth.submited_tasks,
      staffs: state => state.company.staffs,
      calendars: state => state.company.calendars,
      resources: state => state.company.resources,
      courses: state => state.company.courses,
      menus: state => state.company.menus,
      sections: state => state.company.sections,
      payment_methods: state => state.company.payment_methods,
      item_categories: state => state.company.item_categories,
      items: state => state.company.items,
      training_categories: state => state.company.training_categories,
      training_events: state => state.company.training_events,
      training_machines: state => state.company.training_machines,
      training_muscles: state => state.company.training_muscles,
      time_zones: state => state.company.time_zones,
      surveys: state => state.company.surveys,
      selectedClient: state => state.company.selectedClient,
      latest_clients: state => state.company.latest_clients,

      reload_at: state => state.event.reload_at,
      client_trend_at: state => state.event.client_trend_at,
      cl_payment_at: state => state.event.cl_payment_at
    }),
    filterd_time_zones() {
      let time_zones = [{ id: null, name: '-- 未設定 --' }]
      return time_zones.concat(this.time_zones)
    },
    clientLoginUrl() {
      if (this.company.hide_gyms_bland) {
        return `${process.env.CUSTOMER_URL}/?campaign_uid=${this.company.uid}`
      } else {
        return `${process.env.CUSTOMER_URL}`
      }
    }
  },
  methods: {
    generateUUID() {
      return window.crypto.randomUUID()
    },
    limitDateOver(limit_date) {
      if (!limit_date) return false
      let today = dayjs()
      return today.isAfter(dayjs(limit_date))
    },
    autoLink(text) {
      return _.isString(text)
        ? text.replace(
            /(https?:\/\/[^\s]*)/g,
            "<a href='$1' target='_blank'>$1</a>"
          )
        : ''
    },
    copyToClipborad(text, ref = null) {
      if (ref) {
        this.$copyText(text, ref).then(
          function (e) {
            window.storeCtl.commit('alert/setSuccess', 'コピーしました')
          },
          function (e) {
            alert('Can not copy')
          }
        )
      } else {
        this.$copyText(text).then(
          function (e) {
            window.storeCtl.commit('alert/setSuccess', 'コピーしました')
          },
          function (e) {
            alert('Can not copy')
          }
        )
      }
    },
    // document
    filterDocs(docs, doc_ids) {
      return _.filter(docs, doc => {
        return _.includes(doc_ids, doc.id)
      })
    },
    async getClient(client_uid){
      if(!client_uid) return
      return httpClient
        .get(`/cmp/floor/clients/${client_uid}.json`)
        .then(async (res) => {
          if (res.data.status === 'success') {
            return res.data.data.client
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
            return null
          }
        })
    },
    async getDocuments(document_ids) {
      let self = this
      let documents = []
      documents = await httpClient
        .get('/cmp/floor/docs.json', { params: { doc_ids: doc_ids } })
        .then(async res => {
          if (res.data.status === 'success') return res.data.data.docs
        })
      return documents
    },

    async getRelatedLessons(lessson_ids) {
      let self = this
      await httpClient
        .get(`/cmp/floor/lessons/list_by_ids.json`, {
          params: { lesson_ids: lessson_ids.join(',') }
        })
        .then(async res => {
          if (res.data.status === 'success') {
            this.relatedLessons = res.data.data.lessons
          } else {
            self.$store.commit('alert/setError', res.data.message)
          }
        })
    }
  }
}
