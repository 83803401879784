import { httpClient } from '~/utils/axios'
import _ from 'lodash'

export default {
  data() {
    return {}
  },
  async mounted() {},
  computed: {},
  methods: {
    //- 入会
    async join(input_client = null) {
      let self = this
      let current_client = self.client
      if (input_client) current_client = input_client
      self.$bvModal
        .msgBoxConfirm(
          `${current_client.name}様の入会処理してよろしいですか？`,
          {
            okTitle: 'はい',
            cancelTitle: 'いいえ',
            centered: true
          }
        )
        .then(async confirm => {
          if (confirm) {
            self.$store.commit('loading/start')
            await httpClient
              .put(`/cmp/floor/clients/${current_client.uid}/join.json`)
              .then(async res => {
                window.storeCtl.commit('loading/stop')
                if (res.data.status === 'success') {
                  self.$emit('updated', res.data.data.client)
                  if (input_client) self.search()
                  window.storeCtl.commit('alert/setSuccess', '処理しました')
                  self.$refs.edit_client_course.openClientCourse(
                    res.data.data.client
                  )
                } else {
                  window.storeCtl.commit('alert/setError', res.data.message)
                }
              })
          }
        })
    }
  }
}
