
export default {
  components: {
  },
  props: {
    color: String,
  },
  data() {
    return {
      init_color: null,
      edit_color: null,
      editing: false,
    }
  },
  async mounted() {
    this.init_color = this.color
    this.edit_color = this.color
  },
  watch: {
    color(val){
      this.init_color = val
      this.edit_color = val
    },
  },
  computed: {
  },
  methods: {
    update_color(e){
      // console.log("update_color",e)
      this.edit_color = e.hexa
    },
    reset(){
      this.editing = false
    },
    set(){
      this.init_color = this.edit_color
      this.editing = false
      console.log("set", this.edit_color)
      this.$emit("change", this.edit_color)
    },
  },
}
