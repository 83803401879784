
import _ from 'lodash';
import { httpClient } from '~/utils/axios'
import { client_trend } from '~/utils/models'
import { scrollToTop } from '~/utils/tool'
import dayjs from 'dayjs';
import Common from '~/mixins/common'
import ClientTrendList from '~/components/common/client_trend/List'

export default {
  mixins: [Common],
  components: {
    ClientTrendList,
  },
  props: {
    client: {type: Object, default: null},
  },
  data() {
    return {
      // data
      freeword: null,
      waiting_reply: false,
      in_review_service: false,
      date_gteq: null,
      date_lteq_end_of_day: null,
      pages: {
        total_count: 1,
        total_pages: 1,
        current_page: 1,
      },
      currentClientTrends: [],
    }
  },
  async mounted() {
    if(this.client){
      this.date_gteq = dayjs().subtract(31,"d").format('YYYY-MM-DD')
      this.date_lteq_end_of_day = dayjs().format('YYYY-MM-DD')
      this.waiting_reply = false
    }else{
      this.waiting_reply = true
    }
    this.search()
  },
  computed: {
    mergedClientTrends(){
      let self = this
      let client_trends = []
      if(self.client === null){
        client_trends = _.cloneDeep(self.currentClientTrends)
      }else{
        let date = dayjs(self.date_lteq_end_of_day)
        for(let i = 0; i < 31; i++){
          let target_client_trend = _.find(self.currentClientTrends, ["date", date.format("YYYY-MM-DD")])
          if(target_client_trend){
            client_trends.push(target_client_trend)
          }else{
            let new_client_trend = _.cloneDeep(client_trend)
            new_client_trend.date = date.format("YYYY-MM-DD")
            client_trends.push(new_client_trend)
          }
          date = date.subtract(1,"d")
        }        
      }
      return client_trends
    },
  },
  methods: {
    async nextPage(){
      this.date_gteq = dayjs(this.date_gteq).add(31,"d").format('YYYY-MM-DD')
      this.date_lteq_end_of_day = dayjs(this.date_lteq_end_of_day).add(31,"d").format('YYYY-MM-DD')
      await this.search()
    },
    async prevePage(){
      this.date_gteq = dayjs(this.date_gteq).subtract(31,"d").format('YYYY-MM-DD')
      this.date_lteq_end_of_day = dayjs(this.date_lteq_end_of_day).subtract(31,"d").format('YYYY-MM-DD')
      await this.search()
    },

    clientTrendNext(){

    },
    clientTrendPrev(){

    },

    // card
    async clear() {
      this.freeword = null
      this.waiting_reply = false
      if(this.client){
        this.date_gteq = dayjs().subtract(31,"d").format('YYYY-MM-DD')
        this.date_lteq_end_of_day = dayjs().format('YYYY-MM-DD')
        this.waiting_reply = false
      }else{
        this.date_gteq = null
        this.date_lteq_end_of_day = null
        this.waiting_reply = true
      }
      await this.search()
    },

    async autoSearch(){
      this.pages = {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
      }
      await this.search()
    },
    async search(){
      let self = this

      // set params
      let params = {
        "q[client_uid_or_client_name_or_client_headline_or_client_inner_memo_cont]": self.freeword,
        "q[date_gteq]": self.date_gteq,
        "q[date_lteq_end_of_day]": self.date_lteq_end_of_day,
        client_id: self.client ? self.client.id : null,
        page: self.pages.current_page,
        waiting_reply: self.waiting_reply,
      }
      if(self.in_review_service){
        let new_params = {"q[client_in_logs_review_service_eq]": true}
        params = _.merge({}, params, new_params); 
      }
      window.storeCtl.commit("loading/start")
      await httpClient
      .get('/cmp/floor/client_trends.json', {params: params})
      .then(async (res) => {
        if (res.data.status === 'success') {
          self.currentClientTrends = res.data.data.client_trends
          self.pages = res.data.data.pages
          scrollToTop()
          self.$emit("changeSearch", res.data.data.pages)
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
      .finally(() => {
        window.storeCtl.commit("loading/stop")
      })
    },
  }
}
