
import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import Common from '~/mixins/common'
import Masters from '~/mixins/masters'
import Reserve from '~/mixins/reserve'
import { timeOptions, sleep } from '~/utils/tool'
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
import holiday from 'holiday-jp';
import InputColor from '~/components/util/InputColor'

export default {
  mixins: [Common,Masters,Reserve],
  components: {
    InputColor,
  },
  props: {
    client: Object,
  },
  data() {
    return {
      showImportModal: false,
      timeOptions: timeOptions(), // 時間

      multiAllDone: false,

      edit_lesson_type: null,
      edit_lesson_block_name: null,
      edit_lesson_memo: null,
      edit_lesson_inner_memo: null,
      edit_lesson_color: null,
      edit_lesson_calendar_id: null,
      edit_lesson_client_id: null,
      edit_lesson_menu_id: null,

      // 時間周り
      lessonStartDate: null,
      lessonStartHour: null,
      lessonEndDate: null,
      lessonEndHour: null,

      // 一括予約用
      edit_wdays: [],
      wDayOptions: [
        {value: 0, text: "日"},
        {value: 1, text: "月"},
        {value: 2, text: "火"},
        {value: 3, text: "水"},
        {value: 4, text: "木"},
        {value: 5, text: "金"},
        {value: 6, text: "土"},
      ],
      holidaySetting: "none",
      holydayOptions: [
        {value: "none", text: "設定なし"},
        {value: "only", text: "祝日のみ"},
        {value: "exclude", text: "祝日除外"},
      ],
      importLessonLists: [],
      
      // 候補出し
      showSettingModal: false,
    }
  },
  async mounted() {
    let self = this

  },
  watch: {
  },
  computed: {
    title(){
      if(this.edit_lesson_type === 'lesson') return "一括レッスン登録"
      if(this.edit_lesson_type === 'calendar_block') return "一括トレーナーブロック"
      if(this.edit_lesson_type === 'shop_block') return "一括店舗ブロック"
    },
    selectedMenu(){
      return _.find(this.menus, ["id", this.edit_lesson_menu_id])
    },
    filterdResources(){
      if(!this.selectedMenu) return []
      return _.filter(this.resources, (res) => {
        return this.selectedMenu.able_resource_ids.includes(res.id)
      })
    },
    filteredLessonLists(){
      return _.filter(this.importLessonLists, (lst) => {
        return lst.excute_status === "before" || lst.excute_status === "error"
      })
    },
  },
  methods: {    
    // 一括追加モーダル
    openImportModal(edit_lesson_type){
      if(!this.staff.can_reserve) return
      this.getClients()
      
      // FIXME:メニューがないケース度するか
      this.edit_lesson_menu_id = this.cardOnlyExcludeMenus[0].id
      let menu = _.find(this.cardOnlyExcludeMenus, ["id", this.edit_lesson_menu_id])

      this.edit_lesson_type = edit_lesson_type
      this.edit_lesson_block_name = "ブロック"
      this.edit_lesson_color = "#bebebe"
      this.edit_lesson_calendar_id = this.calendars.length > 0 ? this.calendars[0].id : null
      this.edit_lesson_client_id = this.client ? this.client.id : null
      this.edit_lesson_menu_id = menu ? menu.id :null
      this.importLessonLists = []
      this.multiAllDone = false
      this.showImportModal = true
    },


    // クライアント変更時に紐づくメニュー設定
    changeClient(client_id){
      let selectedClient = _.find(this.clients, ["id", client_id])
      if(selectedClient && selectedClient.base_menu_id){
        let selectedMenu = _.find(this.menus, ["id", selectedClient.base_menu_id])
        if(selectedMenu){
          this.edit_lesson_menu_id = selectedMenu.id
        }
      }
    },
    // メニュー変更したらメニューの時間設定
    changeMenu(){
      console.log("changeMenu")
      this.importLessonLists.forEach(list => {
        this.changeStartAt(list)
      })
    },
    // メニューがあればメニュー時間を反映、メニューなければ元の時間幅を維持
    detectEndDayjs(start_dayjs, end_dayjs = null, diff_m = null){
      let menu = _.find(this.cardOnlyExcludeMenus, ["id", this.edit_lesson_menu_id])
      let new_end_dajs = null
      // 終了日が指定されていないケース
      if(end_dayjs){
        new_end_dajs = start_dayjs.add(diff_m, "m")
      }else{
        let reserve_min = this.edit_lesson_type === "lesson" ? menu.reserve_min : 60
        new_end_dajs = start_dayjs.add(reserve_min, "m")
      }
      return new_end_dajs
    },
    // 各リストの開始時間変更したら時間を保って終了を変更
    changeStartAt(list){
      let start_dayjs = dayjs(`${list.start_date} ${list.start_hour}`)
      let end_dayjs = dayjs(`${list.end_date} ${list.end_hour}`)
      end_dayjs = this.detectEndDayjs(start_dayjs, end_dayjs, list.diff_m)
      list.start_date = start_dayjs.format("YYYY-MM-DD")
      list.start_hour = start_dayjs.format("HH:mm")
      list.start_at = start_dayjs.format("YYYY-MM-DD HH:mm")
      list.end_date = end_dayjs.format("YYYY-MM-DD")
      list.end_hour = end_dayjs.format("HH:mm")
      list.end_at = end_dayjs.format("YYYY-MM-DD HH:mm")
      list.diff_m =  end_dayjs.diff(start_dayjs, "m")
      this.checkInValid(list)
    },
    changeEndAt(list){
      console.log("changeEndAt",list)
      let start_dayjs = dayjs(`${list.start_date} ${list.start_hour}`)
      let end_dayjs = dayjs(`${list.end_date} ${list.end_hour}`)
      list.end_date = end_dayjs.format("YYYY-MM-DD")
      list.end_hour = end_dayjs.format("HH:mm")
      list.end_at = end_dayjs.format("YYYY-MM-DD HH:mm")
      list.diff_m =  end_dayjs.diff(start_dayjs, "m")
      this.checkInValid(list)
    },
    //------- 　処理
    sortLessonList(){
      this.importLessonLists = _.sortBy(this.importLessonLists, "start_at")
    },
    clearLessonList(){
      this.importLessonLists = []
    },
    removeLessonList(index){
      this.importLessonLists.splice(index, 1);
    },
    copyList(){
      let list = _.cloneDeep(this.importLessonLists[this.importLessonLists.length - 1])
      this.importLessonLists.push(list)
    },
    addLessonList(){
      let base_dayjs = dayjs().add(1,"d")
      
      // メニューによって時間を変更
      let start_dayjs = dayjs(`${base_dayjs.format("YYYY-MM-DD")} 12:00`)
      let end_dayjs = this.detectEndDayjs(start_dayjs, null, null)

      let list = {
        excute_status: "before",
        calendar_id: null,
        resource_id: null,
        start_date: start_dayjs.format(`YYYY-MM-DD`),
        start_hour: start_dayjs.format("HH:mm"),
        end_date: end_dayjs.format(`YYYY-MM-DD`),
        end_hour: end_dayjs.format("HH:mm"),
        start_at: start_dayjs.format(`YYYY-MM-DD HH:mm`),
        end_at: end_dayjs.format(`YYYY-MM-DD HH:mm`),
        diff_m: end_dayjs.diff(start_dayjs, "m"),
        calendar_error: false,
        term_error: false,
      }
      this.importLessonLists.push(list)
    },
    checkInValid(list){
      let hasError = false
      if(dayjs(list.start_at).isAfter(dayjs(list.end_at))){
        list.term_error = true
        hasError = true
      }else{
        list.term_error = false
      }
      if(this.edit_lesson_type !== "shop_block" && list.calendar_id === null){
        list.calendar_error = true
        hasError = true
      }else{
        list.calendar_error = false
      }
      return hasError
    },
    async importLesson(){
      let self = this
      
      // おかしい日付リストがあるかチェック
      let hasError = false
      self.importLessonLists.forEach(list => {
        if(this.checkInValid(list)) hasError = true
      })
      if(hasError){
        window.storeCtl.commit("alert/setError", "入力項目にエラーがありますので、見直してください")
        return
      }

      self.$bvModal.msgBoxConfirm("この内容で実行してよろしいですか？", {
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          window.storeCtl.commit("loading/start", true)
          let hasError = false
          for(let i = 0; i < self.importLessonLists.length; i++){
            // 処理件数表示
            let loading_text = `${self.importLessonLists.length}件中${i+1}件目を処理中`
            window.storeCtl.commit("loading/updateText", loading_text)
            // 処理中
            let list = self.importLessonLists[i]
            // 対象でなければスルー
            if(!(list.excute_status === "before" || list.excute_status === "error")) continue;
            // 途中でエラーが起こったらそこで中断
            if(hasError) continue;
            // するしなえれば実行中にしない
            list.excute_status = "doing"
            // 少し間隔開ける
            await sleep(1)
            // データ準備
            let payload = {
              isBlock: self.edit_lesson_type === "lesson" ? false : true,
              isAllDay: false,
              block_name: self.edit_lesson_block_name,
              memo: self.edit_lesson_memo,
              inner_memo: self.edit_lesson_inner_memo,
              color: self.edit_lesson_color,
              date: list.start_date,
              start: list.start_at,
              end: list.end_at,
              calendar_id: self.edit_lesson_type === "shop_block" ? null : list.calendar_id,
              resource_id: list.resource_id,
              client_id: self.edit_lesson_client_id,
              menu_id: self.edit_lesson_menu_id,
              check_has_ticket: true,
              push: false,
            }
            let res = await this.createLesson(payload)
            if(res){
              // 成功
              list.excute_status = "success"
            }else{
              // 失敗
              list.excute_status = "error"
              hasError = true
            }
            // 処理が終わった
            self.multiAllDone = true
          } // for
          window.storeCtl.commit("loading/stop")
        } // confirm
      })
    },
    async createLesson(payload){
      return await httpClient
        .post('/cmp/floor/lessons.json', payload)
        .then(async (res) => {
          if (res.data.status === 'success') {
            return true
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
            return false
          }
        })
    },

    //------ 候補モーダル
    openSettingModal(){
      let menu = _.find(this.cardOnlyExcludeMenus, ["id", this.edit_lesson_menu_id])
      let reserve_min = this.edit_lesson_type === "lesson" ? menu.reserve_min : 60
      this.edit_wdays = []
      this.holidaySetting = "none"
      let base_dayjs = dayjs(`${dayjs().format("YYYY-MM-DD")} 12:00`)
      this.lessonStartHour = base_dayjs.format("HH:mm") // dayjs().format("12:00")
      this.lessonEndHour = base_dayjs.add(reserve_min, 'm').format("HH:mm") // dayjs().format("HH:00")
      this.lessonStartDate = base_dayjs.add(1, "M").startOf('month').format('YYYY-MM-DD')
      this.lessonEndDate = base_dayjs.add(1, "M").endOf('month').format('YYYY-MM-DD')
      this.showSettingModal = true
    },
    // 全日チェック
    allWdayCheck(){
      this.edit_wdays = [0,1,2,3,4,5,6]
    },
    setAllDay(){
      this.lessonStartHour = "00:00"
      this.lessonEndHour = "24:00"
    },
    // 候補リスト作成
    generateLessonList(){
      let self = this
      if(dayjs(`2023/1/1 ${self.lessonStartHour}`).isAfter(dayjs(`2023/1/1 ${self.lessonEndHour}`))){
        window.storeCtl.commit("alert/setError", "開始時間は終了時間よりも前に設定してください")
        return
      }
      if(this.edit_lesson_type != "block_with_shop" && this.edit_lesson_calendar_id === null){
        window.storeCtl.commit("alert/setError", "トレーナーを選んでください")
        return
      }
      // console.log("edit_wdays", self.edit_wdays)
      let current_day = dayjs(self.lessonStartDate)
      while (current_day.isSameOrBefore(dayjs(self.lessonEndDate))) {
        let toGenerate = false
        // console.log(current_day, current_day.format('d'))
        if(current_day.format('d') === String(0) && self.edit_wdays.includes(0)) toGenerate = true
        if(current_day.format('d') === String(1) && self.edit_wdays.includes(1)) toGenerate = true
        if(current_day.format('d') === String(2) && self.edit_wdays.includes(2)) toGenerate = true
        if(current_day.format('d') === String(3) && self.edit_wdays.includes(3)) toGenerate = true
        if(current_day.format('d') === String(4) && self.edit_wdays.includes(4)) toGenerate = true
        if(current_day.format('d') === String(5) && self.edit_wdays.includes(5)) toGenerate = true
        if(current_day.format('d') === String(6) && self.edit_wdays.includes(6)) toGenerate = true
        
        // 祝日チェック
        // 作成対象で絞り込みならチェック
        if(toGenerate === true && self.holidaySetting !== "none"){
          let isHoliday = holiday.isHoliday(current_day.toDate())
          if(self.holidaySetting === "only" && isHoliday === false) toGenerate = false
          if(self.holidaySetting === "exclude" && isHoliday === true) toGenerate = false
        }
        
        if(toGenerate){
          let start_dayjs = dayjs(`${current_day.format(`YYYY-MM-DD`)} ${self.lessonStartHour}`)
          let end_dayjs = dayjs(`${current_day.format(`YYYY-MM-DD`)} ${self.lessonEndHour}`)
          let list = {
            excute_status: "before",
            calendar_id: self.edit_lesson_calendar_id,
            resource_id: null,
            start_date: start_dayjs.format(`YYYY-MM-DD`),
            start_hour: start_dayjs.format(`HH:mm`),
            end_date: end_dayjs.format(`YYYY-MM-DD`),
            end_hour: end_dayjs.format(`HH:mm`),
            start_at: start_dayjs.format(`YYYY-MM-DD HH:mm`),
            end_at: end_dayjs.format(`YYYY-MM-DD HH:mm`),
            diff_m: end_dayjs.diff(start_dayjs, "m"),
            calendar_error: false,
            term_error: false,
          }
          this.importLessonLists.push(list)
        }
        
        current_day = current_day.add(1,"d")
      }
      this.showSettingModal = false
    },
  },
}
