
import dayjs from 'dayjs';
import Common from '~/mixins/common'
import _ from 'lodash';
import { httpClient } from '~/utils/axios'
import { course, form_config, planTypeOptions, listExtendForMonthlyOptions } from '~/utils/models'
import DocumentInputs from '~/components/util/docs/DocumentInputs'
import ImageFileInput from '~/components/util/ImageFileInput'

export default {
  mixins: [Common],
  components: {
    DocumentInputs,
    ImageFileInput,
  },
  props: {
  },
  data() {
    return {
      showModal: false,
      course_id: null,
      edit_client: null,
      edit_course: null,

      // 月回数制
      stock_lesson_size: 0,
      stock_lesson_size_next: 0,
      stock_lesson_size_next2: 0,

      // 予約ルール設定
      planTypeOptions: planTypeOptions,
      listExtendForMonthlyOptions: listExtendForMonthlyOptions,
      handleChangeTicket: false,
    }
  },
  async mounted() {
    let self = this
  },
  computed: {
    currentCourse(){
      if(!this.edit_client) return null
      return _.find(this.courses, ["id", this.edit_client.course_id])
    },
    currentFilterdCourses(){
      let arranged_courses = []
      this.courses.forEach(c => {
        if(!c.use_new_version) return
        if(c.only_for_buy) return
        let new_c = _.cloneDeep(c)
        if(new_c.use_subscriptions){
          new_c.name = `[コース]${new_c.name}`
        }else{
          new_c.name = `[回数券]${new_c.name}`
        }
        arranged_courses.push(new_c)
      })
      return arranged_courses
    },
  },
  methods: {
    copyToEveryMonth(){
      this.stock_lesson_size = this.edit_course.monthly_stock_lesson_size
      this.stock_lesson_size_next = this.edit_course.monthly_stock_lesson_size
      this.stock_lesson_size_next2 = this.edit_course.monthly_stock_lesson_size
    },

    async openClientCourse(client){
      this.course_id = null
      this.edit_course = null
      this.stock_lesson_size = 0
      this.stock_lesson_size_next = 0
      this.stock_lesson_size_next2 = 0
      await httpClient
        .get(`/cmp/floor/clients/${client.uid}.json`)
        .then(async (res) => {
          if (res.data.status === 'success') {
            this.edit_client = res.data.data.client
            this.showModal = true
          }else{
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
    },
    changeCourse(){
      this.edit_course = _.cloneDeep(_.find(this.courses, ["id", this.course_id]))
      if(this.edit_course.reserve_type === "monthly_stock"){
        this.stock_lesson_size = this.edit_course.monthly_stock_lesson_size
        this.stock_lesson_size_next = this.edit_course.monthly_stock_lesson_size
        this.stock_lesson_size_next2 = this.edit_course.monthly_stock_lesson_size
      }else{
        this.stock_lesson_size = 0
        this.stock_lesson_size_next = 0
        this.stock_lesson_size_next2 = 0
      }
    },
    async update(){
      let self = this
      self.$store.commit("loading/start")
      await httpClient
        .put(`/cmp/floor/clients/${self.edit_client.uid}/change_course.json`, {
          course: this.edit_course,
          course_id: this.course_id,
          stock_lesson_size: this.stock_lesson_size,
          stock_lesson_size_next: this.stock_lesson_size_next,
          stock_lesson_size_next2: this.stock_lesson_size_next2,
        })
        .then(async (res) => {
          window.storeCtl.commit("loading/stop")
          if (res.data.status === 'success') {
            window.storeCtl.commit("company/setClient", res.data.data.client)
            self.showModal = false
            self.$emit("updated", res.data.data.client)
            window.storeCtl.commit("alert/setSuccess", "更新しました")
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
    },
  },
}
