import _ from 'lodash'
import dayjs from 'dayjs'

export let form_config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
}

export let staff = {
  name: null,
  email: null,
  recieve_reservation_alert: true,
  recieve_log_alert: false,
  can_client: true,
  can_reserve: true,
  can_lesson: true,
  can_log: true,
  can_payment: true,
  can_point: true,
  can_alert: true,
  can_settings: true,
  can_data: true
}

export let client = {
  status: 'active',
  id: null,
  name: null,
  email: null
}

export let calendar = {
  id: null,
  name: null,
  g_calendar_id: null,
  sort: 1,
  priority: 1,
  color: '#db0000',
  email: null,
  attendance_setting: {
    can_sunday: true,
    can_monday: true,
    can_thuseday: true,
    can_wedensday: true,
    can_thursday: true,
    can_friday: true,
    can_saturday: true,
    can_holiday: true,
    manage_holiday: false,
    from_on_sunday: null,
    from_on_monday: null,
    from_on_thuseday: null,
    from_on_wedensday: null,
    from_on_thursday: null,
    from_on_friday: null,
    from_on_saturday: null,
    from_on_holiday: null,
    to_on_sunday: null,
    to_on_monday: null,
    to_on_thuseday: null,
    to_on_wedensday: null,
    to_on_thursday: null,
    to_on_friday: null,
    to_on_saturday: null,
    to_on_holiday: null
  }
}

export let resource = {
  id: null,
  name: null,
  sort: 1,
  priority: 1,
  color: '#db0000'
}

export let lesson = {
  id: null,
  name: null,
  g_calendar_id: null
}

export let client_trend = {
  date: null,
  weight: null,
  fat_rate: null,
  muscle_weight: null,
  fat_weight: null,
  water_weight: null,
  sleep_from: null,
  sleep_to: null,
  sleep_hour: null,
  condition: null,
  alcohol: null,
  breakfast_memo: null,
  lunch_memo: null,
  dinner_memo: null,
  other_memo: null,
  breakfast_reply: null,
  lunch_reply: null,
  dinner_reply: null,
  other_reply: null,
  summary_reply: null,
  client_rating: null,
  daialy: null,
  data: null,
  manage_food: null,
  no_alcohol: null,
  do_walkiing: null,
  do_toilet: null,
  food_doc_ids: [],
  chats: [],
}

export let cl_payment = {
  id: null,
  payment_type: 'item',
  name: null,
  price_subtotal: 0,
  spend_point: 0,
  disacount_price_sum: 0,
  price_sum: 0,
  tax_sum: 0,
  normal_tax_sum: 0,
  keigen_tax_sum: 0,
  azukari_price: 0,
  otsuri_price: 0,

  // ticket
  description: null,
  ticket_number: 1,
  stock_number: 1,
  use_from: dayjs().format('YYYY-MM-DD'),
  use_to: dayjs().add(60, 'd').format('YYYY-MM-DD'),
  expire_days: 60,
  able_menu_ids: []
}

export let point = {
  id: null,
  title: null,
  price: 0,
  use_type: null,
  limit_date: null
}

export let menu = {
  sort: 1,
  name: '',
  name_inner: '',
  description: '',
  show_client: true,
  reserve_min: 60,
  lesson_min: 60,
  calender_unit_handle_edit: false,
  calender_unit: 60,
  calender_unit_lists: '',
  calender_unit_ojb: {
    sunday: '',
    monday: '',
    thuseday: '',
    wedensday: '',
    thursday: '',
    friday: '',
    saturday: '',
    holiday: ''
  },
  max_seat_size: 1,
  need_ticket_size: 1,
  pay_as_you_go: false,
  can_pay_onshop: true,
  price: 0,
  restrict_calendar: false,
  restrict_resource: false,
  able_calendar_ids: [],
  able_resource_ids: [],
  option_settings: {
    use_menu_option: false,
    use_calendar_option: false,
    can_pay_on_shop: false,
    menu_option_price: 0,
    menu_options: [],
    calendar_options: []
  },
  select_calendar: true
}

export let course = {
  use_new_version: true,
  sort: 1,
  time_zone_id: null,
  base_menu_id: null,
  name: '',
  description: '',
  price: 110000,
  ticket_number: 10,
  expire_days: 60,
  show_client_in_join: false,
  show_client_in_buy: false,
  only_for_buy: false,
  able_menu_ids: [],
  client_able_menu_ids: [],
  overwrite_client_settings: false,
  reserve_type: 'stock_limit',
  monthly_stock_lesson_size: 0,
  limit_extend_for_monthy: 1,
  max_reserve_size: null,
  max_reserve_size_by_day: null,
  max_reserve_size_by_week: null,
  max_reserve_size_by_month: null,
  use_subscriptions: false,
  next_subsc_ticket_start_next_month: false,
  next_subsc_ticket_end_next_month: false,
  lesson_price: 0,
  lesson_term: 7
}

export let time_zone = {
  sort: 1,
  name: '',
  name_inner: '',
  can_0: false,
  can_1: false,
  can_2: false,
  can_3: false,
  can_4: false,
  can_5: false,
  can_6: false,
  can_7: false,
  can_8: false,
  can_9: false,
  can_10: false,
  can_11: false,
  can_12: false,
  can_13: false,
  can_14: false,
  can_15: false,
  can_16: false,
  can_17: false,
  can_18: false,
  can_19: false,
  can_20: false,
  can_21: false,
  can_22: false,
  can_23: false,
  settings: {
    lists:   [
      {
        wday: 0,
        name: "日曜",
        can_0: false,
        can_1: false,
        can_2: false,
        can_3: false,
        can_4: false,
        can_5: false,
        can_6: false,
        can_7: false,
        can_8: false,
        can_9: false,
        can_10: false,
        can_11: false,
        can_12: false,
        can_13: false,
        can_14: false,
        can_15: false,
        can_16: false,
        can_17: false,
        can_18: false,
        can_19: false,
        can_20: false,
        can_21: false,
        can_22: false,
        can_23: false,    
      },
      {
        wday: 1,
        name: "月曜",
        can_0: false,
        can_1: false,
        can_2: false,
        can_3: false,
        can_4: false,
        can_5: false,
        can_6: false,
        can_7: false,
        can_8: false,
        can_9: false,
        can_10: false,
        can_11: false,
        can_12: false,
        can_13: false,
        can_14: false,
        can_15: false,
        can_16: false,
        can_17: false,
        can_18: false,
        can_19: false,
        can_20: false,
        can_21: false,
        can_22: false,
        can_23: false,    
      },
      {
        wday: 2,
        name: "火曜",
        can_0: false,
        can_1: false,
        can_2: false,
        can_3: false,
        can_4: false,
        can_5: false,
        can_6: false,
        can_7: false,
        can_8: false,
        can_9: false,
        can_10: false,
        can_11: false,
        can_12: false,
        can_13: false,
        can_14: false,
        can_15: false,
        can_16: false,
        can_17: false,
        can_18: false,
        can_19: false,
        can_20: false,
        can_21: false,
        can_22: false,
        can_23: false,    
      },    
      {
        wday: 3,
        name: "水曜",
        can_0: false,
        can_1: false,
        can_2: false,
        can_3: false,
        can_4: false,
        can_5: false,
        can_6: false,
        can_7: false,
        can_8: false,
        can_9: false,
        can_10: false,
        can_11: false,
        can_12: false,
        can_13: false,
        can_14: false,
        can_15: false,
        can_16: false,
        can_17: false,
        can_18: false,
        can_19: false,
        can_20: false,
        can_21: false,
        can_22: false,
        can_23: false,    
      },    
      {
        wday: 4,
        name: "木曜",
        can_0: false,
        can_1: false,
        can_2: false,
        can_3: false,
        can_4: false,
        can_5: false,
        can_6: false,
        can_7: false,
        can_8: false,
        can_9: false,
        can_10: false,
        can_11: false,
        can_12: false,
        can_13: false,
        can_14: false,
        can_15: false,
        can_16: false,
        can_17: false,
        can_18: false,
        can_19: false,
        can_20: false,
        can_21: false,
        can_22: false,
        can_23: false,    
      },    
      {
        wday: 5,
        name: "金曜",
        can_0: false,
        can_1: false,
        can_2: false,
        can_3: false,
        can_4: false,
        can_5: false,
        can_6: false,
        can_7: false,
        can_8: false,
        can_9: false,
        can_10: false,
        can_11: false,
        can_12: false,
        can_13: false,
        can_14: false,
        can_15: false,
        can_16: false,
        can_17: false,
        can_18: false,
        can_19: false,
        can_20: false,
        can_21: false,
        can_22: false,
        can_23: false,    
      },    
      {
        wday: 6,
        name: "土曜",
        can_0: false,
        can_1: false,
        can_2: false,
        can_3: false,
        can_4: false,
        can_5: false,
        can_6: false,
        can_7: false,
        can_8: false,
        can_9: false,
        can_10: false,
        can_11: false,
        can_12: false,
        can_13: false,
        can_14: false,
        can_15: false,
        can_16: false,
        can_17: false,
        can_18: false,
        can_19: false,
        can_20: false,
        can_21: false,
        can_22: false,
        can_23: false,    
      },
      {
        wday: 7,
        name: "祝日",
        can_0: false,
        can_1: false,
        can_2: false,
        can_3: false,
        can_4: false,
        can_5: false,
        can_6: false,
        can_7: false,
        can_8: false,
        can_9: false,
        can_10: false,
        can_11: false,
        can_12: false,
        can_13: false,
        can_14: false,
        can_15: false,
        can_16: false,
        can_17: false,
        can_18: false,
        can_19: false,
        can_20: false,
        can_21: false,
        can_22: false,
        can_23: false,    
      },
    ]
  }
}

export let survey = {
  status: 'active',
  name: '',
  name_inner: '',
  description: '',
  use_scene: null,
  only_onece: true,
  start: null,
  end: null,
  survey_questions: [],
  disactive_survey_questions: []
}

export let survey_question = {
  id: null,
  sort: 1,
  title: '',
  description: '',
  style: 'text',
  required: true,
  choices: [
    {
      value: ''
    }
  ]
}

export let task = {
  title: '',
  memo: '',
  doc_ids: []
}

export let item_category = {
  sort: 1,
  name: '',
  color: '',
  show_client: true
}

export let item = {
  sort: 1,
  name_inner: '',
  name: '',
  description: '',
  price: 0,
  tax_rate: 10,
  manage_stock: false,
  stock_size: 0,
  show_client: true,
  item_category_id: null,
  section_id: null
}

export let section = {
  sort: 1,
  name: '',
  color: ''
}

export let payment_method = {
  sort: 1,
  name: '',
  color: '',
  is_cash: false
}

export let ticket_set = {
  name: '',
  description: '',
  price: 0,
  ticket_number: 1,
  stock_number: 1,
  use_from: dayjs().format('YYYY-MM-DD'),
  use_to: dayjs().add(365, 'd').format('YYYY-MM-DD'),
  expire_days: 365
}

export let goal_term = {
  start_staff_id: null,
  end_staff_id: null,
  begining_lesson_id: null,
  end_lesson_id: null,
  begining_bodyscan_id: null,
  end_bodyscan_id: null,
  start_front_img: null,
  start_side_img: null,
  start_back_img: null,
  end_front_img: null,
  end_side_img: null,
  end_back_img: null,
  title: null,
  memo: null,
  start_comment: null,
  end_comment: null,
  start_date: null,
  goal_date: null,
  datas: {
    start: {
      weight: null,
      fat_rate: null,
      bmi: null,
      neck_girth_length: null,
      upper_arm_girth_length: null,
      bust_girth_length: null,
      waist_girth_length: null,
      hip_girth_length: null,
      thigh_girth_length: null,
      max_weight_deadlift: null,
      max_weight_squat: null,
      max_weight_bench_press: null,
    },
    end: {
      weight: null,
      fat_rate: null,
      bmi: null,
      neck_girth_length: null,
      upper_arm_girth_length: null,
      bust_girth_length: null,
      waist_girth_length: null,
      hip_girth_length: null,
      thigh_girth_length: null,
      max_weight_deadlift: null,
      max_weight_squat: null,
      max_weight_bench_press: null,
    },
    goal: {
      weight: null,
      fat_rate: null,
      bmi: null,
      neck_girth_length: null,
      upper_arm_girth_length: null,
      bust_girth_length: null,
      waist_girth_length: null,
      hip_girth_length: null,
      thigh_girth_length: null,
      max_weight_deadlift: null,
      max_weight_squat: null,
      max_weight_bench_press: null,
    },
  }
}

export let training_category = {
  name: null,
  name_kana: null,
  show_client: true
}

export let training_event = {
  training_category_id: null,
  sort: 1,
  name: null,
  name_kana: null,
  show_client: true,
  media: {
    doc_ids: [],
    video_ids: []
  },
  img_icon: null,
  headline: null,
  target_muscles: [],
  machine: [],
  pof_type: null,
  difficulty: null,
  guide_memo: null,
  inner_memo: null,
  weight_measure: '重量',
  count_measure: '回数',
  weight_unit: 'kg',
  count_unit: '回',
  level: null
}

// 種目のトレ履歴
export let training_set = {
  weight: null,
  count: 10,
  is_giri: 0,
  is_support: 0,
  support_count: null,
  memo: null,
  event: {
    weight_unit: 'kg',
    count_unit: '回'
  }
}

export let training_list = {
  training_category_id: null,
  uid: null,
  sort: 1,
  bookmark: false,
  name: '',
  description: '',
  target_muscles: [],
  machine: [],
  pof_type: null,
  difficulty: null,
  training_events: {
    event_sets: []
  }
}

export let training_muscle = {
  uid: null,
  status: null,
  sort: 1,
  img_icon: null,
  name: '',
  description: '',
  show_client: false,
}

export let training_machine = {
  uid: null,
  status: null,
  sort: 1,
  img_icon: null,
  name: '',
  description: '',
  show_client: false,
}

// 個人のトレ記録
export let client_training_event = {
  uid: null,
  status: null,
  favarite_type: '',
  doc_ids: '',
  memo: null,
  inner_memo: null,
  max_weight: null,
  max_sum_weight: null,
  count_at_gym: null,
  count_at_self: null,
  latest_at_gym: null,
  latest_by_self: null,
  histories: []
}

///// カスタムフォーム対応 --------------------

// // 個人のカスタム属性
// export let custom_client_attrs = {
//   uid: null,
//   name: null,
//   type: null,
//   options: "",
//   showTrialForm: false,
//   showInitForm: false,
//   required: false,
//   show: true,
// }

// export let custom_goal_attrs = {
//   uid: null,
//   name: null,
//   type: null,
//   options: "",
//   show: true,
//   color: "black",
// }

// レッスンの記録
export let custom_lesson_attrs = {
  uid: null,
  name: null,
  type: 'kg',
  options: '',
  required: false,
  show: true,
  chart: false,
  color: 'black',
  deleted_at: null
}

// 日々のライフログ
export let custom_client_trend_attrs = {
  uid: null,
  name: null,
  type: 'text',
  options: '',
  required: false,
  show: true,
  chart: false,
  color: 'black',
  deleted_at: null
}

export let custom_lesson_options = [
  { text: 'cm', value: 'cm' },
  { text: 'kg', value: 'kg' },
  { text: '数字', value: 'number' }
]

export let custom_client_trend_options = [
  { text: '文字列', value: 'text' },
  { text: 'テキストエリア', value: 'textarea' },
  { text: '数字', value: 'number' },
  { text: 'kg', value: 'kg' },
  { text: '%', value: 'rate' },
  { text: '選択', value: 'radio' },
  { text: '複数選択', value: 'checkbox' },
  { text: '画像', value: 'image' },
  { text: 'ファイル', value: 'file' }
]

///// status --------------------

export let training_event_status = [
  { text: '未設定', value: 'never' },
  { text: '苦手', value: 'not_good' },
  { text: '上手', value: 'good' },
  { text: '非常に上手', value: 'very_good' },
  { text: '完了', value: 'done' }
]

export let clientStatusOptions = [
  { text: 'ビジター', value: 'trial' },
  { text: '会員', value: 'active' },
  { text: '停止中', value: 'disactive' }
]

export let subscTypeOptions = [
  { text: '1日払い', value: 'pay_at_first' },
  { text: '購入日指定', value: 'pay_at_date' },
  { text: '定期期間払い', value: 'pay_with_term' }
]

export let planTypeOptions = [
  { value: null, text: '選んでください' },
  { value: 'unlimit', text: '無制限' },
  { value: 'stock', text: 'チケット(簡易)' },
  { value: 'stock_limit', text: 'チケット(厳密)' },
  { value: 'monthly_stock', text: '月回数制限' },
  { value: 'weekly_limit', text: '週回数制限' }
]

export let sexOptions = [
  { value: null, text: '選んでください' },
  { value: 'male', text: '男性' },
  { value: 'female', text: '女性' },
  { value: 'other', text: 'その他' }
]

export let listExtendForMonthlyOptions = [
  { text: '当月中のみ', value: 1 },
  { text: '2ヶ月有効', value: 2 }
  // { text: '3ヶ月有効', value: 3 },
]

export let reserveUnitOptions = [
  { value: null, text: '選んでください' },
  { value: 5, text: '5分' },
  { value: 10, text: '10分' },
  { value: 15, text: '15分' },
  { value: 20, text: '20分' },
  { value: 25, text: '25分' },
  { value: 30, text: '30分' },
  { value: 35, text: '35分' },
  { value: 40, text: '40分' },
  { value: 45, text: '45分' },
  { value: 50, text: '50分' },
  { value: 55, text: '55分' },
  { value: 60, text: '60分' },
  { value: 65, text: '65分' },
  { value: 70, text: '70分' },
  { value: 75, text: '75分' },
  { value: 80, text: '80分' },
  { value: 85, text: '85分' },
  { value: 90, text: '90分' },
  { value: 95, text: '95分' },
  { value: 100, text: '100分' },
  { value: 105, text: '105分' },
  { value: 110, text: '110分' },
  { value: 115, text: '115分' },
  { value: 120, text: '120分' },
  { value: 125, text: '125分' },
  { value: 130, text: '130分' },
  { value: 135, text: '135分' },
  { value: 140, text: '140分' },
  { value: 145, text: '145分' },
  { value: 150, text: '150分' },
  { value: 155, text: '155分' },
  { value: 160, text: '160分' },
  { value: 165, text: '165分' },
  { value: 170, text: '170分' },
  { value: 175, text: '175分' },
  { value: 180, text: '180分' }
]

export let unitOptions = [
  { value: null, text: '選んでください' },
  { value: 5, text: '5分' },
  { value: 10, text: '10分' },
  { value: 15, text: '15分' },
  { value: 30, text: '30分' },
  { value: 60, text: '60分' }
]

export let taxRateOptions = [
  { text: '10%', value: 10 },
  { text: '8%', value: 8 }
  // { text: '0%', value: 0 },
]

export let lessonTypeOptions = [
  { id: 'lesson', name: 'レッスン' },
  { id: 'block', name: 'トレーナーブロック' },
  { id: 'block_with_shop', name: '店舗ブロック' }
]

export let tableModeStatus = [
  { text: 'テーブル', value: 'table' },
  { text: 'カード', value: 'card' }
]

export let eventHeadlines = [
  { type: 'all', label: '全て' },
  { type: 'ア', label: 'ア', active: true },
  { type: 'イ', label: 'イ', active: true },
  { type: 'ウ', label: 'ウ', active: true },
  { type: 'エ', label: 'エ', active: true },
  { type: 'オ', label: 'オ', active: true },
  { type: 'カ', label: 'カ', active: true },
  { type: 'キ', label: 'キ', active: true },
  { type: 'ク', label: 'ク', active: true },
  { type: 'ケ', label: 'ケ', active: true },
  { type: 'コ', label: 'コ', active: true },
  { type: 'サ', label: 'サ', active: true },
  { type: 'シ', label: 'シ', active: true },
  { type: 'ス', label: 'ス', active: true },
  { type: 'セ', label: 'セ', active: true },
  { type: 'ソ', label: 'ソ', active: true },
  { type: 'タ', label: 'タ', active: true },
  { type: 'チ', label: 'チ', active: true },
  { type: 'ツ', label: 'ツ', active: true },
  { type: 'テ', label: 'テ', active: true },
  { type: 'ト', label: 'ト', active: true },
  { type: 'ナ', label: 'ナ', active: true },
  { type: 'ニ', label: 'ニ', active: true },
  { type: 'ヌ', label: 'ヌ', active: true },
  { type: 'ネ', label: 'ネ', active: true },
  { type: 'ノ', label: 'ノ', active: true },
  { type: 'ハ', label: 'ハ', active: true },
  { type: 'ヒ', label: 'ヒ', active: true },
  { type: 'フ', label: 'フ', active: true },
  { type: 'ヘ', label: 'ヘ', active: true },
  { type: 'ホ', label: 'ホ', active: true },
  { type: 'マ', label: 'マ', active: true },
  { type: 'ミ', label: 'ミ', active: true },
  { type: 'ム', label: 'ム', active: true },
  { type: 'メ', label: 'メ', active: true },
  { type: 'モ', label: 'モ', active: true },
  { type: 'ヤ', label: 'ヤ', active: true },
  { type: 'ユ', label: 'ユ', active: true },
  { type: 'ヨ', label: 'ヨ', active: true },
  { type: 'ラ', label: 'ラ', active: true },
  { type: 'リ', label: 'リ', active: true },
  { type: 'ル', label: 'ル', active: true },
  { type: 'レ', label: 'レ', active: true },
  { type: 'ロ', label: 'ロ', active: true },
  { type: 'ワ', label: 'ワ', active: true },
  { type: 'other', label: 'その他', active: true }
]

export let surveySceneOptions = [
  // {value: "client_create", text: "新規会員登録時"},
  { value: 'lesson_trial', text: '体験予約時', disabled: false },
  // {value: "lesson_reserve", text: "通常予約時"},
  { value: 'client_join', text: '入会時', disabled: false },
  // {value: "course_change", text: "コース変更時"},
  // {value: "client_destroy", text: "退会時"},
  { value: 'free', text: 'フリー', disabled: true }
]

export let questionStyleOptions = [
  { value: 'text', text: 'テキスト(1行)' },
  { value: 'textarea', text: 'テキスト(複数行)' },
  { value: 'radio', text: 'ラジオボタン(1つ選択)' },
  { value: 'checkbox', text: 'チェックボックス(複数選択)' },
  { value: 'date', text: '日付' }
]

export let taskStatusOptions = [
  { text: '対応中', value: 'inprogress' },
  { text: '完了', value: 'done' },
  { text: '削除', value: 'pending' }
]

export let statusOptions = [
  { text: '有効', value: 'active' },
  { text: '無効', value: 'disactive' }
]
