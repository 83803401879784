
import _ from 'lodash';
import { httpClient } from '~/utils/axios'
import dayjs from 'dayjs';
import { scrollToTop, timeOptions, sleep } from '~/utils/tool'
import Common from '~/mixins/common'
import LessonTypeChip from '~/components/common/lesson/TypeChip'
import LessonStatusChip from '~/components/common/lesson/StatusChip'
import LessonEdit from '~/components/common/lesson/Edit'
import LessonPartPrice from '~/components/common/lesson/PartPrice'
import DocumentInputs from '~/components/util/docs/DocumentInputs'
import VideoInputs from '~/components/util/videos/VideoInputs'
import ReservationEdit from '~/components/common/reservation/Edit'
import IconAvatar from '~/components/util/IconAvatar'
import LessonTrainingEvents from '~/components/common/lesson/TrainingEvents'
import LessonBodyData from '~/components/common/lesson/BodyData'

export default {
  mixins: [Common],
  components: {
    LessonTypeChip,
    LessonStatusChip,
    LessonEdit,
    LessonPartPrice,
    LessonTrainingEvents,
    LessonBodyData,
    DocumentInputs,
    VideoInputs,
    ReservationEdit,
    IconAvatar,
  },
  props: {
    client: {type: Object, default: null},
    filter: {type: String, default: null},
    showClient: {type: Boolean, default: false},
    status: {type: Array, default: null},
  },
  data() {
    return {
      showFilter: false,

      // data
      waiting_self_comment: false,
      has_review: false,
      waiting_reply: false,
      uuid_eq: null,
      freeword: null,
      status_eq_any: ["done","reserved"],
      lessonStatusOptions: [
        { text: '予約中', value: "reserved" },
        { text: '受講済み', value: "done" },
        { text: '当日キャンセル', value: "no_show" },
        { text: 'キャンセル', value: "canceled" },
      ],
      data_type_eq_any: ["gym","self"],
      dataTypeOptions: [
        { text: 'パーソナル', value: "gym" },
        { text: '自主トレ', value: "self" },
        { text: '計測', value: "measurement" },
      ],
      date_gteq: null,
      date_lteq_end_of_day: null,
      onlyBlock: false,
      onlyBlockStatus: [
        { text: '予約・レッスン', value: false },
        { text: 'ブロック', value: true },
      ],

      // data
      pages: {
        total_count: 1,
        total_pages: 1,
        current_page: 1,
      },
      currentLessons: [],

      // replay
      showReplyModal: false,
      edit_lesson: null,

      // 一括処理
      multiEditMode: null,
      showLessonsEditModal: false,
      multiAllDone: false,
      editSelectedLessons: [],
      timeOptions: timeOptions(), // 時間
    }
  },
  async mounted() {
    let self = this
    // status
    if(self.status) self.status_eq_any = self.status
    // filter
    if(self.filter) self.status_eq_any = [self.filter]
    if(self.$route.query.term){
      if(self.$route.query.term === "today"){
        self.date_gteq = dayjs().format('YYYY-MM-DD')
        self.date_lteq_end_of_day = dayjs().format('YYYY-MM-DD')
      }
      if(self.$route.query.term === "tommorrow"){
        self.date_gteq = dayjs().add(1,"d").format('YYYY-MM-DD')
        self.date_lteq_end_of_day = dayjs().add(1,"d").format('YYYY-MM-DD')
      }
    }
    this.search()
  },
  computed: {
    selectedLessons(){
      return _.filter(this.currentLessons, ls => {
        return ls.is_selected
      })
    },
  },
  methods: {
    selectLesson(lesson){
      let newLessons = []
      this.currentLessons.forEach(ls => {
        if(lesson.id === ls.id){
          ls.is_selected = ls.is_selected ? false : true
        }
        newLessons.push(ls)
      })
      this.currentLessons = newLessons

    },
    selectAllRows() {
      let newLessons = []
      this.currentLessons.forEach(ls => {
        ls.is_selected = true
        newLessons.push(ls)
      })
      this.currentLessons = newLessons
    },
    clearSelected() {
      let newLessons = []
      this.currentLessons.forEach(ls => {
        ls.is_selected = false
        newLessons.push(ls)
      })
      this.currentLessons = newLessons
    },

    //// アクション
    // キャンセル・当日キャンセル
    updateStatus(lesson, action_type){
      let self = this
      let title = null
      let message = null
      if(action_type === "no_show"){
        title = "<span class='text-danger'>当日キャンセル</span>"
        message = "チケットや支払いは払い戻されません。"
      }
      if(action_type === "canceled"){
        title = "キャンセル"
        message = "チケット・都度払いは払い戻されます。"
      }
      self.$bvModal.msgBoxConfirm(message, {
        titleHtml: title,
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          self.$store.commit("loading/start")
          let res = await self.updateStatusLesson(lesson,action_type)
          window.storeCtl.commit("loading/stop")
          if(res) window.storeCtl.commit("alert/setSuccess", "処理しました")
        }
      })
    },
    // ブロック削除
    async confirmDeleteBlock(lesson){
      let self = this
      self.$bvModal.msgBoxConfirm(`ブロックを削除します。`, {
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          self.$store.commit("loading/start")
          let res = await self.deleteBlock(lesson)
          window.storeCtl.commit("loading/stop")
          if(res) self.search()
        }
      })
    },

    //// API
    // レッスン時間変更
    async changeLessonTime(lesson){
      let self = this
      lesson.start = `${lesson.date} ${lesson.start_hm}`
      lesson.end = `${lesson.date} ${lesson.end_hm}`
      if(dayjs(lesson.start).isAfter(dayjs(lesson.end))){
        window.storeCtl.commit("alert/setError", "開始時間は終了時間よりも前に設定してください")
        return
      }
      return await httpClient
        .put(`/cmp/floor/lessons/${lesson.uid}/change.json`, {
          block_name: lesson.block_name,
          memo: lesson.memo,
          inner_memo: lesson.inner_memo,
          color: lesson.color,
          calendar_id: lesson.calendar_id,
          start: lesson.start,
          end: lesson.end,
          push: false,
        })
        .then(async (res) => {
          if (res.data.status === 'success') {
            self.updateLessonLists(res.data.data.lesson)
            return true
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
            return false
          }
        })
    },
    // キャンセル
    async updateStatusLesson(lesson, action_type){
      return await httpClient
        .put(`/cmp/floor/lessons/${lesson.uid}/update_status.json`, {
          action_type: action_type,
        })
        .then(async (res) => {
          if (res.data.status === 'success') {
            this.updateLessonLists(res.data.data.lesson)
            return true
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
            return false
          }
        })
    },
    // ブロック削除
    async deleteBlock(lesson){
      return await httpClient
        .delete(`/cmp/floor/lessons/${lesson.uid}/delete_block.json`)
        .then(async (res) => {
          if (res.data.status === 'success') {
            return true
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
            return false
          }
        })
    },
    // 更新時のCallback
    updateLessonLists(lesson){
      let newLessons = []
      this.currentLessons.forEach(ls => {
        if(ls.id === lesson.id){
          let new_lesson = _.cloneDeep(lesson)
          new_lesson.is_selected = ls.is_selected
          newLessons.push(new_lesson)
        }else{
          newLessons.push(ls)
        }
      })
      this.currentLessons = newLessons
    },

    // replay ---------------
    openReplyReview(lesson){
      this.edit_lesson = _.cloneDeep(lesson)
      this.showReplyModal = true
    },
    async sendReply(){
      let self = this
      self.$store.commit("loading/start")
      await httpClient
        .put(`/cmp/floor/lessons/${self.edit_lesson.uid}/reply.json`, {lesson: self.edit_lesson})
        .then(async (res) => {
          window.storeCtl.commit("loading/stop")
          if (res.data.status === 'success') {
            window.storeCtl.commit("loading/stop")
            self.updateLessonLists(res.data.data.lesson)
            self.showReplyModal = false
            window.storeCtl.commit("alert/setSuccess", "送信しました")
          } else {
            window.storeCtl.commit("loading/stop")
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          window.storeCtl.commit("loading/stop")
        })
    },

    //// 一括変更処理
    editLessons(multiEditMode){
      this.multiEditMode = multiEditMode
      let editSelectedLessons = []
      let selectedLessons = _.cloneDeep(this.selectedLessons)
      selectedLessons.forEach(ls => {
        if(['reserved','block'].includes(ls.status) && ls.disactive_at === null){
          ls.excute_status = "before"
          ls.start_hm = dayjs(ls.start).format("HH:mm")
          ls.end_hm = dayjs(ls.end).format("HH:mm")
          editSelectedLessons.push(ls)
        }
      })
      this.editSelectedLessons = editSelectedLessons
      this.multiAllDone = false
      this.showLessonsEditModal = true
    },
    changeHour(lesson){
      let reserve_min = dayjs(lesson.end).diff(dayjs(lesson.start), 'minute')
      let start = dayjs(`2023/1/1 ${lesson.start_hm}`)
      lesson.end_hm = dayjs(start).add(reserve_min, "m").format("HH:mm")
    },
    deleteSelectedLessons(index){
      this.editSelectedLessons.splice(index, 1);
    },
    async excuteLessonsUpdate(){
      let self = this
      window.storeCtl.commit("loading/start")
      for(let i = 0; i < self.editSelectedLessons.length; i++){
        let res = null
        let lesson = self.editSelectedLessons[i]
        lesson.excute_status = "doing"
        if(self.multiEditMode === 'changeTime') res = await self.changeLessonTime(lesson)
        if(self.multiEditMode === 'delete') res = await self.deleteBlock(lesson)
        if(self.multiEditMode === 'cancel') res = await self.updateStatusLesson(lesson, "canceled")
        lesson.excute_status = res ? "success" : "error"
        
        // 少し間隔開ける
        await sleep(1)
      }
      this.multiAllDone = true
      this.search()
      window.storeCtl.commit("loading/stop")
    },

    //// 検索周り
    // search
    async clear() {
      this.waiting_self_comment = false
      this.has_review = false
      this.waiting_reply = false
      this.freeword = null
      this.status_eq_any = []
      this.data_type_eq_any = ["gym","self"]
      this.date_gteq = null
      this.date_lteq_end_of_day = null
      this.pages = {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
      }
      await this.search()
    },
    async autoSearch(){
      this.pages = {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
      }
      await this.search()
    },
    async search(){
      let self = this
      // set params
      let params = {
        waiting_self_comment: self.waiting_self_comment,
        has_review: self.has_review,
        waiting_reply: self.waiting_reply,
        "q[uid_or_block_name_or_memo_or_inner_memo_or_client_name_or_client_uid_cont]": self.freeword,
        "q[date_gteq]": self.date_gteq,
        "q[date_lteq_end_of_day]": self.date_lteq_end_of_day,
        "status_eq_any": self.onlyBlock ? ["block"] : self.status_eq_any,
        "data_type_eq_any": self.onlyBlock ? [] : self.data_type_eq_any,
        client_id: self.client ? self.client.id : null,
        page: self.pages.current_page,
        onlyBlock: self.onlyBlock,
      }
      window.storeCtl.commit("loading/start")
      await httpClient
      .get('/cmp/floor/lessons.json', {params: params})
      .then(async (res) => {
        if (res.data.status === 'success') {
          self.currentLessons = res.data.data.lessons
          self.pages = res.data.data.pages

          // 前処理
          self.currentLessons.forEach(ls => {
            if(ls.is_selected === undefined) ls.is_selected = false
          })

          scrollToTop()
          self.$emit("changeSearch", res.data.data.pages)
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
      .finally(() => {
        window.storeCtl.commit("loading/stop")
      })
    },
    changeSelfCommentWainting(event){
      console.log("changeSelfCommentWainting",event)
      if(event === true){
        this.status_eq_any = ["done"]
        this.data_type_eq_any = ["self"] 
      }
      this.autoSearch()
    },
  }
}
