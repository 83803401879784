import { httpClient } from '~/utils/axios'
import _ from 'lodash'
import dayjs from 'dayjs'

const getDefaultState = () => {
  return {
    // contact
    firstContact: false,

    // auth
    authToken: null,
    company: null,
    staff: null,
    settings: [],
    submited_tasks: [],
    new_alert_size: 0,

    // push
    lastUpdatedAt: null,
    oneSignalDeviseToken: null
  }
}

export const state = () => getDefaultState()
export const mutations = {
  // contact
  firstContactDone(state, payload) {
    state.firstContact = true
  },

  // auth
  setAuthToken(state, auth_token) {
    state.authToken = auth_token
  },
  setCompany(state, company) {
    state.company = company
  },
  setStaff(state, staff) {
    state.staff = staff
  },
  setSettings(state, settings) {
    state.settings = settings
  },
  setSubmitedTasks(state, submited_tasks) {
    state.submited_tasks = submited_tasks
  },
  setLastUpdatedAt(state, payload) {
    state.lastUpdatedAt = payload
  },
  setNewAlertSize(state, new_alert_size) {
    state.new_alert_size = new_alert_size
  },
  setOneSignalDeviseToken(state, oneSignalDeviseToken) {
    state.oneSignalDeviseToken = oneSignalDeviseToken
  },

  logout(state) {
    $nuxt.$router.push(`/login/`)
    Object.assign(state, getDefaultState())
    // firebase disconnect
    window.storeCtl.dispatch('fireStore/destroy')
    window.storeCtl.commit('company/reset')
    window.storeCtl.commit('devise/reset')
  }
}
export const getters = {
  isLogin(state) {
    return !!state.authToken
  }
}

// 戻り地はisBeforeRequest
export const actions = {
  async refreshToken(context) {
    await httpClient
      .post('/cmp/account/refresh_token.json')
      .then(async res => {
        if (res.data.status === 'success') {
          if(res.data.data.is_admin === false) context.commit('setAuthToken', res.data.data.auth_token)
          console.log("refreshToken",res.data.data.expire_at)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
  },

  async getUpdate(context) {
    await httpClient
      .get('/cmp/has_update.json')
      .then(async res => {
        if (res.data.status === 'success') {
          // alert size
          context.commit('setNewAlertSize', res.data.data.new_alert_size)

          // マスタの更新が新しければマスタGET
          let latest_update_at = dayjs(
            res.data.data.company_updated_at
          ).isAfter(dayjs(res.data.data.staff_updated_at))
            ? dayjs(res.data.data.company_updated_at)
            : dayjs(res.data.data.staff_updated_at)
          let last_update_at = dayjs(context.state.lastUpdatedAt)
          if (
            context.state.lastUpdatedAt === null ||
            latest_update_at.isAfter(last_update_at)
          ) {
            context.commit('setLastUpdatedAt', res.data.data.company_updated_at)

            // ここで一度マスターをすべて取得
            await window.storeCtl.dispatch('auth/getStaff')
          }
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
      .finally(() => {
        window.storeCtl.commit('loading/stop')
      })
  },

  //// push
  // ログインしていて
  // LocalStrage取れていて
  // StoreとLocalstrageの値が違う
  // サーバー同期処理
  async updateOneSignalDeviseToken(context) {
    const onesignal_user_id = localStorage.getItem('onesignal_user_id')
    if (
      onesignal_user_id &&
      window.storeCtl.state.auth.oneSignalDeviseToken !== onesignal_user_id
    ) {
      await httpClient
        .post('/cmp/account/update_onesignal_device_token.json', {
          onesignal_device_token: onesignal_user_id
        })
        .then(res => {
          if (res.data.status === 'success') {
            window.storeCtl.commit(
              'auth/setOneSignalDeviseToken',
              onesignal_user_id
            )
          } else {
            window.storeCtl.commit('alert/setError', res.data.message)
          }
        })
    }
  },

  async getStaff(context) {
    await httpClient
      .get('/cmp/account/staff.json')
      .then(async res => {
        if (res.data.status === 'success') {
          context.commit('setStaff', res.data.data.staff)
          context.commit('setCompany', res.data.data.company)
          context.commit('setSubmitedTasks', res.data.data.submited_tasks)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
      .finally(() => {})
  },
  async getCompany(context) {
    await httpClient
      .get('/cmp/account/company.json')
      .then(async res => {
        if (res.data.status === 'success') {
          context.commit('setCompany', res.data.data.company)
          context.commit('setSettings', res.data.data.settings)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
      .finally(() => {})
  },

  async getAllData(context) {
    await window.storeCtl.dispatch('auth/getUpdate')
    await window.storeCtl.dispatch('auth/getStaff'),
    await window.storeCtl.dispatch('auth/getCompany'),
    await window.storeCtl.dispatch('auth/updateOneSignalDeviseToken')
    await window.storeCtl.dispatch('company/getStaffs')
    await window.storeCtl.dispatch('company/getCalendars')
    await window.storeCtl.dispatch('company/getResources')
    await window.storeCtl.dispatch('company/getCourses')
    await window.storeCtl.dispatch('company/getMenus')
    await window.storeCtl.dispatch('company/getSections')
    await window.storeCtl.dispatch('company/getPaymentMethods')
    await window.storeCtl.dispatch('company/getItemCategories')
    await window.storeCtl.dispatch('company/getItems')
    await window.storeCtl.dispatch('company/getTrainingCategories')
    await window.storeCtl.dispatch('company/getTrainingEvents')
    await window.storeCtl.dispatch('company/getTrainingMachines')
    await window.storeCtl.dispatch('company/getTrainingMuscles')
    // await window.storeCtl.dispatch('company/getTrainingLists')
    await window.storeCtl.dispatch('company/getTimeZones')
  }
}
