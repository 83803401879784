
import _ from 'lodash';
import { httpClient } from '~/utils/axios'
import Masters from '~/mixins/masters'
import dayjs from 'dayjs';
import { point, form_config } from '~/utils/models'
import { scrollToTop } from '~/utils/tool'
import Common from '~/mixins/common'

export default {
  mixins: [Common, Masters],
  components: {
  },
  props: {
    client: {type: Object, default: null},
    filter: {type: String, default: null},
    showClient: {type: Boolean, default: false},
  },
  data() {
    return {
      // data
      uuid_eq: null,
      freeword: null,
      use_type_eq_any: [],
      useTypeOptions: [
        { text: '付与', value: "add" },
        { text: '行使', value: "spend" },
      ],
      created_at_gteq: null,
      created_at_lteq_end_of_day: null,

      pages: {
        total_count: 1,
        total_pages: 1,
        current_page: 1,
      },
      currentPoints: [],

      // modal
      showModal: false,
      modalTitle: null,
      edit_point: null,

      // 人を選ぶケース
      edit_client_uid: null,
    }
  },
  async mounted() {
    let self = this
    // filter
    if(self.filter) self.use_type_eq_any = [self.filter]
    this.search()
    
    // 顧客リストGet
    if(!self.client) this.getClients()
  },
  computed: {
  },
  methods: {
    pointNew(){
      if(this.client){
        this.edit_client_uid = _.cloneDeep(this.client.uid)
      }else{
        this.edit_client_uid = null
      }
      this.modalTitle = "ポイント付与"
      this.edit_point = _.cloneDeep(point)
      this.edit_point.limit_date = dayjs().add(6, 'month').format('YYYY-MM-DD')
      this.showModal = true
    },
    async pointCreate(){
      let self = this
      self.$store.commit("loading/start", true)
      await httpClient
        .post(`/cmp/floor/points.json`, {
          client_uid: self.edit_client_uid,
          point: self.edit_point
        })
        .then(async (res) => {
          window.storeCtl.commit("loading/stop")
          if (res.data.status === 'success') {
            await self.clear()
            await self.$store.dispatch("company/getClient", self.edit_client_uid)
            window.storeCtl.commit("loading/stop")
            self.showModal = false
            window.storeCtl.commit("alert/setSuccess", "作成しました")
          } else {
            window.storeCtl.commit("loading/stop")
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          window.storeCtl.commit("loading/stop")
        })
    },

    async clear() {
      this.freeword = null
      this.use_type_eq_any = []
      this.created_at_gteq = null
      this.created_at_lteq_end_of_day = null
      this.order = "desc"
      this.pages = {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
      }
      await this.search()
    },
    async autoSearch(){
      this.pages = {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
      }
      await this.search()
    },
    async search(){
      let self = this

      // set params
      let params = {
        "q[uid_or_client_name_or_client_uid_cont]": self.freeword,
        "q[created_at_gteq]": self.created_at_gteq,
        "q[created_at_lteq_end_of_day]": self.created_at_lteq_end_of_day,
        "use_type_eq_any": self.use_type_eq_any,
        client_id: self.client ? self.client.id : null,
        page: self.pages.current_page,
        order: self.order,
      }
      window.storeCtl.commit("loading/start")
      await httpClient
      .get('/cmp/floor/points.json', {params: params})
      .then(async (res) => {
        if (res.data.status === 'success') {
          self.currentPoints = res.data.data.points
          self.pages = res.data.data.pages
          scrollToTop()
          self.$emit("changeSearch", res.data.data.pages)
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
      .finally(() => {
        window.storeCtl.commit("loading/stop")
      })
    },
  }
}
