
import _ from 'lodash';

export default {
  mixins: [],
  components: {
  },
  props: {
    lesson: {type: Object, default: null},
  },
  data() {
    return {
    }
  },
  async mounted() {
  },
  computed: {
  },
  methods: {
  },
}
